import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  StepVoiceoverFileType,
  VOICEOVER_STEP,
  VoiceoverType,
  AdOverlayAndVideoRecordType,
  MirrorConfigurationType,
  MirrorFiltersType,
  NeutralVideoType,
  MirrorNeutralVideos,
  MirrorPhotoType,
  MirrorVoiceoverType,
  ReservationDateType,
  ReservationType,
  TYPES
} from 'src/redux/store/models';

export enum TYPE_VOICEOVER_ENUM {
  STEP1 = 0,
  STEP2 = 1,
  STEP_NOBODY = 2,
  STEP_FIXED_DURATION = 3
}

export interface MirrorConfigurationState {
  loading: boolean;
  error: string;
  filters: MirrorFiltersType;
  saved: boolean;
  voiceoverTab: {
    setting: MirrorVoiceoverType;
    files: StepVoiceoverFileType[];
    voiceovers: VoiceoverType[];
    loading: boolean;
    error: string;
  };
  photoTab: {
    loading: boolean;
    error: string;
    data: MirrorPhotoType;
    dataNotOverride: MirrorPhotoType;
  };
  mirror: MirrorConfigurationType;
  toggling: boolean;
  selectedProfileId: number;
  reservationTab: {
    allData: ReservationType;
    weekData: ReservationType;
    loading: boolean;
    error: string;
    fetched: boolean;
  };
  videoTab: {
    data: NeutralVideoType[];
    mirrorVideos: NeutralVideoType[];
    profileVideos: NeutralVideoType[];
    isOverride: boolean;
    loading: boolean;
    updated: boolean;
    deleted: boolean;
  };
}

export const initialState: MirrorConfigurationState = {
  loading: false,
  error: '',
  filters: {
    profiles: []
  },
  saved: false,
  voiceoverTab: {
    loading: false,
    setting: {
      theFirstIsOverride: false,
      isOverride: false,
      enableNobody: false,
      enableStep1: false,
      enableStep2: false,
      enableFixedDuration: false,
      enableVoiceover: false,
      step1: 0,
      step2: 0,
      step_nobody: 0,
      step_fixed_duration: 0,
      id: 0,
      mirrorId: 0
    },
    error: '',
    voiceovers: [],
    files: [
      {
        step: VOICEOVER_STEP.STEP1,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.STEP2,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.NOBODY,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.FIXED_DURATION,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      }
    ]
  },
  mirror: {
    id: 0,
    uuid: '',
    name: '',
    advertiseMode: '',
    profileId: 0,
    teamviewer: '',
    description: '',
    isAdOverlay: false,
    isVideoRecord: false
  },
  selectedProfileId: 0,
  toggling: false,
  photoTab: {
    loading: false,
    error: '',
    data: {
      headerName: '',
      headerPath: '',
      fullScreenPath: '',
      fullScreenName: '',
      isOverride: false
    },
    dataNotOverride: {
      headerName: '',
      headerPath: '',
      fullScreenPath: '',
      fullScreenName: '',
      isOverride: false
    }
  },
  reservationTab: {
    allData: {
      // all saved dates data from db
      dates: [],
      type: TYPES.HOURLY
    },
    weekData: {
      // selected week dates data to render
      dates: [],
      type: TYPES.HOURLY
    },
    loading: false,
    error: '',
    fetched: false
  },
  videoTab: {
    data: [],
    mirrorVideos: [],
    profileVideos: [],
    isOverride: false,
    loading: false,
    updated: false,
    deleted: false
  }
};

const mirrorConfigurationSlice = createSlice({
  name: 'mirrorConfig',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchFilters(state: MirrorConfigurationState) {},
    filtersFetched(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorFiltersType>
    ) {
      state.filters = action.payload;
    },
    filtersFetchedError(state: MirrorConfigurationState) {
      state.error = 'Fail to fetch filters';
    },
    saveMirror(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorConfigurationType>
    ) {
      state.loading = true;
      state.error = '';
      state.saved = false;
    },
    mirrorSaved(state: MirrorConfigurationState) {
      state.loading = false;
      state.saved = true;
    },
    mirrorSavedError(
      state: MirrorConfigurationState,
      action: PayloadAction<string>
    ) {
      state.error = action.payload;
      state.loading = false;
    },
    setSaved(state: MirrorConfigurationState, action: PayloadAction<boolean>) {
      state.saved = action.payload;
    },
    fetchMirror(
      state: MirrorConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.loading = true;
      state.error = '';
    },
    mirrorFetched(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorConfigurationType>
    ) {
      state.loading = false;
      state.mirror = action.payload;
      state.selectedProfileId = action.payload.profileId;
    },
    mirrorFetchedError(state: MirrorConfigurationState) {
      state.loading = false;
      state.error = 'Fail to load profile';
    },
    fetchVoiceovers(
      state: MirrorConfigurationState,
      action: PayloadAction<{
        mirrorId: number;
        isToggle?: boolean;
        isOverride?: boolean;
      }>
    ) {
      state.voiceoverTab.loading = true;
      state.voiceoverTab.error = '';
    },
    fetchProfileVoiceovers(
      state: MirrorConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.voiceoverTab.loading = true;
      state.voiceoverTab.error = '';
    },
    setVoiceSetting(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorVoiceoverType>
    ) {
      state.voiceoverTab.setting = action.payload;
    },
    setSelectedProfileId(
      state: MirrorConfigurationState,
      action: PayloadAction<number>
    ) {
      state.selectedProfileId = action.payload;
    },
    setFiles(
      state: MirrorConfigurationState,
      action: PayloadAction<StepVoiceoverFileType[]>
    ) {
      state.voiceoverTab.files = action.payload;
      state.voiceoverTab.loading = false;
    },
    setVoiceovers(
      state: MirrorConfigurationState,
      action: PayloadAction<VoiceoverType[]>
    ) {
      state.voiceoverTab.voiceovers = action.payload;
      state.voiceoverTab.loading = false;
    },
    voiceoversNotExisted(state: MirrorConfigurationState) {
      state.voiceoverTab.loading = false;
      state.voiceoverTab.setting = {
        isOverride: false,
        enableNobody: false,
        enableVoiceover: false,
        enableFixedDuration: false,
        step1: 0,
        step2: 0,
        step_nobody: 0,
        step_fixed_duration: 0,
        id: 0,
        mirrorId: 0
      };
      state.voiceoverTab.files = [
        { step: VOICEOVER_STEP.STEP1, files: [] },
        { step: VOICEOVER_STEP.STEP2, files: [] },
        { step: VOICEOVER_STEP.NOBODY, files: [] },
        { step: VOICEOVER_STEP.FIXED_DURATION, files: [] }
      ];
      state.voiceoverTab.error = 'Voiceovers are not existed!';
    },
    resetError(state: MirrorConfigurationState) {
      state.voiceoverTab.error = '';
      state.photoTab.error = '';
      state.reservationTab.error = '';
    },
    toggleAdvOverlayAndRecordVideo(
      state: MirrorConfigurationState,
      action: PayloadAction<AdOverlayAndVideoRecordType>
    ) {
      state.toggling = true;
    },
    toggleDone(state: MirrorConfigurationState) {
      state.toggling = false;
    },
    toggleError(state: MirrorConfigurationState) {
      state.toggling = false;
      state.error = 'Fail to toggle advertise overlay and record video';
    },
    fetchPhotos(
      state: MirrorConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.photoTab.loading = true;
      state.photoTab.error = '';
    },
    fetchProfilePhotos(
      state: MirrorConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.photoTab.loading = true;
      state.photoTab.error = '';
    },
    photosFetched(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorPhotoType>
    ) {
      state.photoTab.loading = false;
      state.photoTab.data = action.payload;
      state.photoTab.dataNotOverride = action.payload;
    },
    photosFetchedError(state: MirrorConfigurationState) {
      state.photoTab.loading = false;
      state.photoTab.error = 'Fail to fetch profile photos';
    },
    photosNotExisted(state: MirrorConfigurationState) {
      state.photoTab.data = initialState.photoTab.data;
      state.photoTab.dataNotOverride = initialState.photoTab.dataNotOverride;
      state.photoTab.loading = false;
      state.photoTab.error = 'Photos are not existed!';
    },
    setPhotoData(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorPhotoType>
    ) {
      state.photoTab.data = action.payload;
    },
    fetchReservation(
      state: MirrorConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.reservationTab.loading = true;
      state.reservationTab.error = '';
      state.reservationTab.fetched = false;
    },
    reservationFetched(
      state: MirrorConfigurationState,
      action: PayloadAction<ReservationType>
    ) {
      state.reservationTab.loading = false;
      if (action.payload) {
        state.reservationTab.allData = action.payload;
      }
      state.reservationTab.fetched = true;
    },
    reservationFetchedError(state: MirrorConfigurationState) {
      state.reservationTab.loading = false;
      state.reservationTab.error = 'Fail to load reservation';
      state.reservationTab.fetched = true;
    },
    setReservationWeekType(
      state: MirrorConfigurationState,
      action: PayloadAction<TYPES>
    ) {
      state.reservationTab.weekData.type = action.payload;
    },
    setReservationWeekDates(
      state: MirrorConfigurationState,
      action: PayloadAction<ReservationDateType[]>
    ) {
      state.reservationTab.weekData.dates = action.payload;
    },
    saveReservation(state: MirrorConfigurationState) {
      state.reservationTab.loading = true;
    },
    reservationSaved(state: MirrorConfigurationState) {
      state.reservationTab.loading = false;
    },
    reservationSavedError(state: MirrorConfigurationState) {
      state.reservationTab.loading = false;
      state.reservationTab.error = 'Fail to save reservation';
    },
    fetchVideos(
      state: MirrorConfigurationState,
      action: PayloadAction<number>
    ) {
      state.videoTab.loading = true;
    },
    videosFetched(
      state: MirrorConfigurationState,
      action: PayloadAction<MirrorNeutralVideos>
    ) {
      state.videoTab.loading = false;
      state.videoTab.isOverride = action.payload.isOverride;
      state.videoTab.mirrorVideos = action.payload.mirrorVideos;
      state.videoTab.profileVideos = action.payload.profileVideos;

      if (action.payload.isOverride) {
        state.videoTab.data = action.payload.mirrorVideos;
      } else {
        state.videoTab.data = action.payload.profileVideos;
      }
    },
    setVideoIsOverride(
      state: MirrorConfigurationState,
      action: PayloadAction<boolean>
    ) {
      state.videoTab.isOverride = action.payload;
      if (action.payload) {
        state.videoTab.data = [...state.videoTab.mirrorVideos];
      } else {
        state.videoTab.data = [...state.videoTab.profileVideos];
      }
    },
    setVideos(
      state: MirrorConfigurationState,
      action: PayloadAction<NeutralVideoType[]>
    ) {
      state.videoTab.data = action.payload;
    },
    updateVideos(state: MirrorConfigurationState) {
      state.videoTab.loading = true;
      state.videoTab.updated = false;
    },
    videosUpdated(state: MirrorConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.updated = true;
    },
    deleteVideo(
      state: MirrorConfigurationState,
      action: PayloadAction<number>
    ) {
      state.videoTab.loading = true;
      state.videoTab.deleted = false;
    },
    videoDeleted(state: MirrorConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.deleted = true;
    },
    setVideoDeleted(
      state: MirrorConfigurationState,
      action: PayloadAction<boolean>
    ) {
      state.videoTab.deleted = action.payload;
    }
  }
});

export default mirrorConfigurationSlice.reducer;
export const mirrorConfigActions = mirrorConfigurationSlice.actions;
