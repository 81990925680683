import { all, call } from 'redux-saga/effects';
import watchSurveyReport from 'src/containers/survey-report/store/saga';
import watchPeopleCounterReport from 'src/containers_v2/people-counter/detailed/store/saga';
import watchMirror from 'src/containers/mirror/store/saga';
import watchUserClickTrackingReport from 'src/containers/user-click-tracking/store/saga';
import watchUserListSaga from 'src/containers_v2/user-management/user-list/store/saga';
import watchUserConfigurationSaga from 'src/containers_v2/user-management/user-configuration/store/saga';
import watchForgotPasswordSaga from 'src/containers_v2/auth/forgot-password/store/saga';
import watchSurveyReportV2Saga from 'src/containers_v2/survey/store/saga';
import watchAuthSaga from 'src/containers_v2/auth/store/saga';
import watchClickTrackingReportV2Saga from 'src/containers_v2/user-click-tracking/store/saga';
import userSummaryV2Sage from 'src/containers_v2/people-counter/summary/store/saga';
import watchMirrorTypeListSaga from 'src/containers_v2/categories/mirror-type/mirror-type-list/store/saga';
import watchContentSaga from 'src/containers_v2/categories/content/content-list/store/saga';
import watchContentConfigurationSaga from 'src/containers_v2/categories/content/content-configuration/store/saga';
import watchMirrorTypeConfigSaga from 'src/containers_v2/categories/mirror-type/mirror-type-configuration/store/saga';
import watchProfileConfigurationSaga from 'src/containers_v2/mirror-configuration/profile/profile-configuration/store/saga';
import watchProfileListSaga from 'src/containers_v2/mirror-configuration/profile/profile-list/store/saga';
import watchMirrorConfigurationSaga from 'src/containers_v2/mirror-configuration/mirror/mirror-configuration/store/saga';
import watchMirrorListSaga from 'src/containers_v2/mirror-configuration/mirror/mirror-list/store/saga';
import watchSnapSaga from 'src/containers_v2/categories/snap/snap-list/store/saga';
import watchSnapConfigurationSaga from 'src/containers_v2/categories/snap/snap-configuration/store/saga';
import watchGroupListSaga from 'src/containers_v2/ar_creator/group/group-list/store/saga';
import watchGroupConfigurationSaga from 'src/containers_v2/ar_creator/group/group-configuration/store/saga';
import watchARTemplateListSaga from 'src/containers_v2/ar_creator/ARTemplate/ar-template-list/store/saga';
import watchARTemplateConfigurationSaga from 'src/containers_v2/ar_creator/ARTemplate/ar-configuration/store/saga';
import watchArProjectConfigurationSaga from 'src/containers_v2/ar_creator/ARProject/configuration/store/saga';
import watchArProjectListSaga from 'src/containers_v2/ar_creator/ARProject/list/store/saga';
import watchQuizListSaga from 'src/containers_v2/puzzle/quiz/quiz-list/store/saga';
import watchQuizConfigurationSaga from 'src/containers_v2/puzzle/quiz/quiz-configuration/store/saga';
import watchARProjectListSaga from 'src/containers_v2/audit/ar-project/ar-project-list/store/saga';
import watchProfileAuditListSaga from 'src/containers_v2/audit/profile/profile-list/store/saga';
import watchUserAuditListSaga from 'src/containers_v2/audit/user/user-list/store/saga';
import watchMirrorAuditListSaga from 'src/containers_v2/audit/mirror/mirror-list/store/saga';
import watchVideosSaga from 'src/containers_v2/categories/videos/videos-list/store/saga';
import watchVideosConfigurationSaga from 'src/containers_v2/categories/videos/videos-configuration/store/saga';
import watchArPamphletConfigurationSaga from 'src/containers_v2/ar_creator/ARPamphlet/configuration/store/saga';
import watchArPamphletListSaga from 'src/containers_v2/ar_creator/ARPamphlet/list/store/saga';
export default function* rootSaga() {
  yield all([
    call(watchSurveyReport),
    call(watchPeopleCounterReport),
    call(watchMirror),
    call(watchUserClickTrackingReport),
    call(watchUserListSaga),
    call(watchUserConfigurationSaga),
    call(watchForgotPasswordSaga),
    call(watchSurveyReportV2Saga),
    call(watchAuthSaga),
    call(watchClickTrackingReportV2Saga),
    call(watchMirrorTypeListSaga),
    call(watchContentSaga),
    call(watchContentConfigurationSaga),
    call(watchMirrorTypeConfigSaga),
    call(watchProfileConfigurationSaga),
    call(watchProfileListSaga),
    call(watchMirrorConfigurationSaga),
    call(watchMirrorListSaga),
    call(watchSnapSaga),
    call(watchSnapConfigurationSaga),
    call(watchGroupListSaga),
    call(watchGroupConfigurationSaga),
    call(watchARTemplateListSaga),
    call(watchARTemplateConfigurationSaga),
    call(watchArProjectConfigurationSaga),
    call(watchArProjectListSaga),
    call(watchQuizConfigurationSaga),
    call(watchQuizListSaga),
    call(watchARProjectListSaga),
    call(watchProfileAuditListSaga),
    call(watchUserAuditListSaga),
    call(watchMirrorAuditListSaga),
    call(userSummaryV2Sage),
    call(watchVideosSaga),
    call(watchVideosConfigurationSaga),
    call(watchArPamphletConfigurationSaga),
    call(watchArPamphletListSaga),
  ]);
  // code after all-effect
}
