import { combineReducers } from 'redux';
import { reducer as surveyReportReducer } from 'src/containers/survey-report/store/slice';
import { reducer as peopleCounterReportReducer } from 'src/containers_v2/people-counter/detailed/store/slice';
import { reducer as mirrorReducer } from 'src/containers/mirror/store/slice';
import { reducer as userClickTrackingReportReducer } from 'src/containers/user-click-tracking/store/slice';
import userSlice from 'src/redux/store/user/slice';
import langSlice from 'src/redux/store/language/slice';
import { reducer as userListReducer } from 'src/containers_v2/user-management/user-list/store/slice';
import { reducer as userConfigurationReducer } from 'src/containers_v2/user-management/user-configuration/store/slice';
import forgotPasswordReducer from 'src/containers_v2/auth/forgot-password/store/slice';
import surveyReportV2Reducer from 'src/containers_v2/survey/store/slice';
import authReducer from 'src/containers_v2/auth/store/slice';
import clickTrackingV2Reducer from 'src/containers_v2/user-click-tracking/store/slice';
import userSummaryV2Reducer from 'src/containers_v2/people-counter/summary/store/slice';
import mirrorTypeListReducer from 'src/containers_v2/categories/mirror-type/mirror-type-list/store/slice';
import contentReducer from 'src/containers_v2/categories/content/content-list/store/slice';
import { reducer as contentConfigurationReducer } from 'src/containers_v2/categories/content/content-configuration/store/slice';
import mirrorTypeConfigReducer from 'src/containers_v2/categories/mirror-type/mirror-type-configuration/store/slice';
import profileConfigReducer from 'src/containers_v2/mirror-configuration/profile/profile-configuration/store/slice';
import profileListReducer from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';
import uploaderReducer from 'src/containers_v2/uploader/store/slice';
import mirrorConfigReducer from 'src/containers_v2/mirror-configuration/mirror/mirror-configuration/store/slice';
import mirrorListReducer from 'src/containers_v2/mirror-configuration/mirror/mirror-list/store/slice';
import snapReducer from 'src/containers_v2/categories/snap/snap-list/store/slice';
import { reducer as snapConfigurationReducer } from 'src/containers_v2/categories/snap/snap-configuration/store/slice';
import { reducer as groupListReducer } from 'src/containers_v2/ar_creator/group/group-list/store/slice';
import { reducer as groupConfigurationReducer } from 'src/containers_v2/ar_creator/group/group-configuration/store/slice';
import UIConfigReducer from 'src/containers_v2/ui-configuration/store/slice';
import { reducer as arTemplateConfiguration } from 'src/containers_v2/ar_creator/ARTemplate/ar-configuration/store/slice';
import { reducer as arTemplateListReducer } from 'src/containers_v2/ar_creator/ARTemplate/ar-template-list/store/slice';
import { reducer as arProjectConfiguration } from 'src/containers_v2/ar_creator/ARProject/configuration/store/slice';
import { reducer as arProjectListReducer } from 'src/containers_v2/ar_creator/ARProject/list/store/slice';
import { reducer as quizListReducer } from 'src/containers_v2/puzzle/quiz/quiz-list/store/slice';
import { reducer as quizConfigurationReducer } from 'src/containers_v2/puzzle/quiz/quiz-configuration/store/slice';
import { reducer as arProjectListAuditReducer } from 'src/containers_v2/audit/ar-project/ar-project-list/store/slice';
import { reducer as profileListAuditReducer } from 'src/containers_v2/audit/profile/profile-list/store/slice';
import { reducer as userListAuditReducer } from 'src/containers_v2/audit/user/user-list/store/slice';
import { reducer as mirrorListAuditReducer } from 'src/containers_v2/audit/mirror/mirror-list/store/slice';
import videosReducer from 'src/containers_v2/categories/videos/videos-list/store/slice';
import { reducer as videosConfigurationReducer } from 'src/containers_v2/categories/videos/videos-configuration/store/slice';
import { reducer as arPamphletConfiguration } from 'src/containers_v2/ar_creator/ARPamphlet/configuration/store/slice';
import { reducer as arPamphletListReducer } from 'src/containers_v2/ar_creator/ARPamphlet/list/store/slice';
import metaverseReducer from 'src/containers_v2/metaverse/store/slice';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    surveyReport: surveyReportReducer,
    peopleCounterReport: peopleCounterReportReducer,
    mirror: mirrorReducer,
    userClickTrackingReport: userClickTrackingReportReducer,
    user: userSlice,
    lang: langSlice,
    userlist: userListReducer,
    userConfiguration: userConfigurationReducer,
    forgotPassword: forgotPasswordReducer,
    surveyReportV2: surveyReportV2Reducer,
    auth: authReducer,
    userClickTrackingV2: clickTrackingV2Reducer,
    mirrorTypeList: mirrorTypeListReducer,
    mirrorTypeConfiguration: mirrorTypeConfigReducer,
    content: contentReducer,
    contentConfiguration: contentConfigurationReducer,
    profileConfig: profileConfigReducer,
    uploader: uploaderReducer,
    profileList: profileListReducer,
    mirrorConfig: mirrorConfigReducer,
    mirrorList: mirrorListReducer,
    snap: snapReducer,
    snapConfiguration: snapConfigurationReducer,
    grouplist: groupListReducer,
    groupConfiguration: groupConfigurationReducer,
    uiConfig: UIConfigReducer,
    arTemplateConfiguration: arTemplateConfiguration,
    arTemplate: arTemplateListReducer,
    arProject: arProjectListReducer,
    arProjectConfiguration: arProjectConfiguration,
    quizlist: quizListReducer,
    quizConfiguration: quizConfigurationReducer,
    arProjectAuditList: arProjectListAuditReducer,
    profileAuditList: profileListAuditReducer,
    userAuditList: userListAuditReducer,
    mirrorAuditList: mirrorListAuditReducer,
    userSummaryV2: userSummaryV2Reducer,
    videos: videosReducer,
    videosConfiguration: videosConfigurationReducer,
    arPamphlet: arPamphletListReducer,
    arPamphletConfiguration: arPamphletConfiguration,
    metaverse: metaverseReducer,
    ...injectedReducers
  });

  return rootReducer;
}
