import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import {
  AdOverlayAndVideoRecordType,
  MirrorConfigurationType,
  MirrorFiltersType,
  MirrorPhotoType,
  OverrideMirrorNeutralVideos,
  ReservationType,
  UpdateNeutralVideoType,
  DeleteVoiceoverFiles,
  StepVoiceoverFileType,
  VoiceoverType
} from 'src/redux/store/models';
import {
  FetchMirrorListParams,
  MirrorListPageResult,
  MirrorVoiceoverResponseType
} from './types';

class MirrorApi {
  async fetchMirrors(
    params: FetchMirrorListParams
  ): Promise<MirrorListPageResult> {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirrors?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as MirrorListPageResult;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchMirrorFilters(): Promise<MirrorFiltersType> {
    try {
      const response: AxiosResponse = await agent.get(`/mirrors/filters`);

      if (response.status === 200) {
        let data = response.data as MirrorFiltersType;
        data.mirrorIds = data.mirrorIds.sort((a, b) =>
          ('' + a).localeCompare(b)
        );

        return data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchMirrorById(
    mirrorId: string | number
  ): Promise<MirrorConfigurationType> {
    try {
      const response: AxiosResponse = await agent.get(`/mirrors/${mirrorId}`);

      if (response.status === 200) {
        return {
          id: response.data.id,
          uuid: response.data.uuid || '',
          name: response.data.name || '',
          description: response.data.description || '',
          teamviewer: response.data.teamviewer || '',
          profileId: response.data.profileId,
          advertiseMode: response.data.advertiseMode,
          isAdOverlay: response.data.isAdOverlay,
          isVideoRecord: response.data.isVideoRecord,
          shopName: response.data.shopName
        };
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveMirror(payload: MirrorConfigurationType): Promise<any> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/upsert`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadVoiceovers(payload: {
    id?: number;
    profileId?: number;
    step1?: number;
    step2?: number;
    step_nobody?: number;
    step_fixed_duration?: number;
    enableVoiceover?: boolean;
    enableNobody?: boolean;
    enableFixedDuration?: boolean;
    mirrorId?: number;
    isOverride?: boolean;
    files?: StepVoiceoverFileType[];
  }): Promise<any> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/overrides/voice-over`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateVoiceovers(payload: VoiceoverType): Promise<any> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/overrides/voice-over`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadVoiceoverFiles(payload: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/mirrors/upload-voiceover`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getMirrorVoiceovers(payload: {
    mirrorId: string | number;
    isToggle: boolean;
    isOverride?: boolean;
  }): Promise<MirrorVoiceoverResponseType> {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirrors/${payload.mirrorId}/voice-over`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async toggleAdOverlayAndVideoRecord(
    payload: AdOverlayAndVideoRecordType
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/${payload.mirrorId}/toggles`,
        payload
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getPhotos(mirrorId: string | number): Promise<MirrorPhotoType> {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirrors/${mirrorId}/photos`
      );

      if (response.status === 200) {
        return {
          bottomName: response.data.bottomName,
          bottomPath: response.data.bottomPath,
          headerName: response.data.headerName,
          headerPath: response.data.headerPath,
          fullScreenName: response.data.modalName,
          fullScreenPath: response.data.modalPath,
          isOverride: response.data.isOverridePhoto
        } as MirrorPhotoType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadPhotos(payload: FormData): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/overrides/photos`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getReservation(mirrorId: string | number): Promise<ReservationType> {
    try {
      const response: AxiosResponse = await agent.get(
        `/reservation/${mirrorId}`
      );

      if (response.status === 200 && response.data) {
        return response.data as ReservationType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveReservation(
    mirrorId: string | number,
    payload: ReservationType
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/reservation/${mirrorId}`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteMirror(mirrorId: string | number): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.delete(
        `/mirrors/${mirrorId}`
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteVoiceovers(
    mirrorId: string | number,
    files: DeleteVoiceoverFiles
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent({
        method: 'delete',
        url: `/mirrors/${mirrorId}/voice-over`,
        data: { ...files }
      });

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getVideos(mirrorId: number) {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirrors/${mirrorId}/neutral-videos`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async addVideos(
    mirrorId: string | number,
    payload: OverrideMirrorNeutralVideos
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/mirrors/${mirrorId}/override/neutral-videos`,
        payload
      );

      if (response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateVideos(
    mirrorId: number,
    payload: UpdateNeutralVideoType
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/mirrors/${mirrorId}/update-videos`,
        payload
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteNeutralVideo(videoId: string | number): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.delete(
        `/mirrors/remove-video/${videoId}`
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async syncProfileVideosToMirror(
    mirrorId: string | number,
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/mirrors/${mirrorId}/neutral-videos/sync-by-profile`,
        {}
      );

      if (response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async syncProfileVideosToOverrideOnMirrors(
    profileId: string | number,
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/mirrors/neutral-videos/sync-by-profile/${profileId}`,
        {}
      );

      if (response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const mirrorApi = new MirrorApi();
export default mirrorApi;
