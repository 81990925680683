import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarLayoutV2 from 'src/layouts/SidebarLayoutV2';
import QRcodeDynamic from './content/pages/QRcodeDynamic';
import { UserRoles } from './utils/constants';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const DetailedReport = Loader(
  lazy(() => import('src/containers_v2/people-counter/detailed'))
);

const SummaryReport = Loader(
  lazy(() => import('src/containers_v2/people-counter/summary/chart/index'))
);

const SurveyReport = Loader(lazy(() => import('src/containers_v2/survey')));
const SurveyChart = Loader(
  lazy(() => import('src/containers_v2/survey/chart'))
);
const UserClickTrackingReport = Loader(
  lazy(() => import('src/containers_v2/user-click-tracking'))
);
const UserClickTrackingChart = Loader(
  lazy(() => import('./containers_v2/user-click-tracking/chart'))
);
const UserList = Loader(
  lazy(() => import('./containers_v2/user-management/user-list'))
);
const UserConfiguration = Loader(
  lazy(() => import('./containers_v2/user-management/user-configuration'))
);
const MirrorTypeList = Loader(
  lazy(() => import('./containers_v2/categories/mirror-type/mirror-type-list'))
);
const MirrorTypeConfiguration = Loader(
  lazy(
    () =>
      import('./containers_v2/categories/mirror-type/mirror-type-configuration')
  )
);
const MirrorProfileConfiguration = Loader(
  lazy(
    () =>
      import(
        './containers_v2/mirror-configuration/profile/profile-configuration'
      )
  )
);

const ContentList = Loader(
  lazy(() => import('./containers_v2/categories/content/content-list'))
);

const ContentListConfiguration = Loader(
  lazy(() => import('./containers_v2/categories/content/content-configuration'))
);

const ContentPromotion = Loader(
  lazy(
    () =>
      import(
        './containers_v2/categories/content/content-configuration/Promotion'
      )
  )
);

const ProfileList = Loader(
  lazy(
    () => import('./containers_v2/mirror-configuration/profile/profile-list')
  )
);

const SnapList = Loader(
  lazy(() => import('./containers_v2/categories/snap/snap-list'))
);
const SnapConfiguration = Loader(
  lazy(() => import('./containers_v2/categories/snap/snap-configuration'))
);

const MirrorConfiguration = Loader(
  lazy(
    () =>
      import('./containers_v2/mirror-configuration/mirror/mirror-configuration')
  )
);
const MirrorList = Loader(
  lazy(() => import('./containers_v2/mirror-configuration/mirror/mirror-list'))
);

const ArProjectList = Loader(
  lazy(() => import('src/containers_v2/ar_creator/ARProject/list/index'))
);

const ArProjectConfiguration = Loader(
  lazy(
    () => import('src/containers_v2/ar_creator/ARProject/configuration/index')
  )
);

const Sketchfab = Loader(
  lazy(() => import('src/containers_v2/ar_creator/Sketcfab/Sketchfab'))
);

const ARPreview = Loader(
  lazy(() => import('src/containers_v2/ar_creator/ARPreview/ARPreview'))
);

const GroupList = Loader(
  lazy(() => import('./containers_v2/ar_creator/group/group-list'))
);

const GroupConfiguration = Loader(
  lazy(() => import('./containers_v2/ar_creator/group/group-configuration'))
);

const QuizList = Loader(
  lazy(() => import('./containers_v2/puzzle/quiz/quiz-list'))
);
const QuizConfiguration = Loader(
  lazy(() => import('./containers_v2/puzzle/quiz/quiz-configuration'))
);

const PostQuiz = Loader(lazy(() => import('src/containers_v2/quiz/QuizPost')));
const Quiz = Loader(lazy(() => import('src/containers_v2/quiz/Quiz')));

const HelpPage = Loader(
  lazy(() => import('src/containers_v2/help-menu/HelpMenu'))
);

const EditorPremiere = Loader(
  lazy(() => import('src/containers_v2/PremierPro/Editor/PremierProEditor'))
);

const ARTemplateList = Loader(
  lazy(() => import('./containers_v2/ar_creator/ARTemplate/ar-template-list'))
);
const ARTemplateConfiguration = Loader(
  lazy(() => import('./containers_v2/ar_creator/ARTemplate/ar-configuration'))
);

const ChangePassword = Loader(
  lazy(() => import('./containers_v2/auth/change-password/index'))
);

const ArProjectAuditList = Loader(
  lazy(() => import('./containers_v2/audit/ar-project/ar-project-list'))
);

const ProfileAuditList = Loader(
  lazy(() => import('./containers_v2/audit/profile/profile-list'))
);

const UserAuditList = Loader(
  lazy(() => import('./containers_v2/audit/user/user-list'))
);

const MirrorAuditList = Loader(
  lazy(() => import('./containers_v2/audit/mirror/mirror-list'))
);

const SpecifiedNotFound = Loader(
  lazy(() => import('./containers_v2/mirror-configuration/SpecifiedNotFound'))
);

const VideosList = Loader(
  lazy(() => import('./containers_v2/categories/videos/videos-list'))
);

const VideosListConfiguration = Loader(
  lazy(() => import('./containers_v2/categories/videos/videos-configuration'))
);

const ArPamphletList = Loader(
  lazy(() => import('src/containers_v2/ar_creator/ARPamphlet/list/index'))
);

const ArPamphletConfiguration = Loader(
  lazy(
    () => import('src/containers_v2/ar_creator/ARPamphlet/configuration/index')
  )
);

const ExpiredQRCode = Loader(
  lazy(() => import('./content/pages/ExpiredQRCode/index'))
);

const IncidentDetailList = Loader(
  lazy(() => import('src/containers_v2/helpdesk/IncidentDetailList'))
);

const MapList = Loader(
  lazy(() => import('src/containers_v2/metaverse/map/MapList'))
);

const MapConfiguration = Loader(
  lazy(() => import('src/containers_v2/metaverse/map/MapConfiguration'))
);

const WorldList = Loader(
  lazy(() => import('src/containers_v2/metaverse/world/WorldList'))
);

const WorldConfiguration = Loader(
  lazy(() => import('src/containers_v2/metaverse/world/WorldConfiguration'))
);

const AreaList = Loader(
  lazy(() => import('src/containers_v2/metaverse/area/AreaList'))
);

const AreaConfiguration = Loader(
  lazy(() => import('src/containers_v2/metaverse/area/AreaConfiguration'))
);

const WeatherConfiguration = Loader(
  lazy(() => import('src/containers_v2/metaverse/weather/WeatherConfiguration'))
);

const FeedbackList = Loader(
  lazy(() => import('src/containers_v2/metaverse/feedback/FeedbackList'))
);

const AnnouncementTextList = Loader(
  lazy(
    () =>
      import(
        'src/containers_v2/metaverse/announcement-text/AnnouncementTextList'
      )
  )
);

const AnnouncementTextConfig = Loader(
  lazy(
    () =>
      import(
        'src/containers_v2/metaverse/announcement-text/AnnouncementTextConfig'
      )
  )
);

const ARNameCardScanList = Loader(
  lazy(
    () =>
      import(
        'src/containers_v2/monitoring/ar-namecard/ARNameCardScanList'
      )
  )
);
const ARPamphletScanList = Loader(
  lazy(
    () =>
      import(
        'src/containers_v2/monitoring/ar-pamphlet/ARPamphletScanList'
      )
  )
);

const admin = UserRoles.toString(UserRoles.Admin);
const mirror = UserRoles.toString(UserRoles.Mirrors_owner);
const ar_owner_namecard = UserRoles.toString(UserRoles.AR_owner_namecard);
const ar_owner_pamphlet = UserRoles.toString(UserRoles.AR_owner_pamphlet);
const ar_admin = UserRoles.toString(UserRoles.AR_admin);
const user_admin = UserRoles.toString(UserRoles.User_admin);
const mirror_viewer = UserRoles.toString(UserRoles.Mirror_viewer);

export const routes = {
  path: 'reports',
  element: <SidebarLayoutV2 />,
  roles: [admin, mirror, ar_owner_namecard, user_admin, mirror_viewer],
  children: [
    {
      path: '/',
      element: <Navigate to="/reports/people-counter/detailed" replace />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: '/',
      element: <Navigate to="/reports/ar-namecard" replace />,
      roles: [ar_owner_namecard]
    },
    {
      path: '/',
      element: <Navigate to="/reports/users-management" replace />,
      roles: [user_admin]
    },
    {
      path: 'people-counter/detailed',
      element: <DetailedReport />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'people-counter/summary',
      element: <SummaryReport />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'survey',
      element: <SurveyReport />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'chart-survey',
      element: <SurveyChart />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'user-click-tracking',
      element: <UserClickTrackingReport />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'chart-user-click-tracking',
      element: <UserClickTrackingChart />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'users-management',
      element: <UserList />,
      roles: [admin, mirror, user_admin, mirror_viewer]
    },
    {
      path: 'users-management/:id',
      element: <UserConfiguration />,
      roles: [admin, user_admin]
    },
    {
      path: 'users-management/create-new-user',
      element: <UserConfiguration />,
      roles: [admin, user_admin]
    },
    {
      path: 'mirror-types',
      element: <MirrorTypeList />,
      roles: [admin]
    },
    {
      path: 'mirror-types/create-new-mirror-type',
      element: <MirrorTypeConfiguration />,
      roles: [admin]
    },
    {
      path: 'mirror-types/:id',
      element: <MirrorTypeConfiguration />,
      roles: [admin]
    },
    {
      path: 'profiles',
      element: <ProfileList />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'profiles/create-new-profile',
      element: <MirrorProfileConfiguration />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'profiles/:id',
      element: <MirrorProfileConfiguration />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'contents',
      element: <ContentList />,
      roles: [admin]
    },
    {
      path: 'contents/create-new-content',
      element: <ContentListConfiguration />,
      roles: [admin]
    },
    {
      path: 'contents/:id',
      element: <ContentListConfiguration />,
      roles: [admin]
    },
    {
      path: 'contents/:id/promote',
      element: <ContentPromotion />,
      roles: [admin]
    },
    {
      path: 'snaps',
      element: <SnapList />,
      roles: [admin]
    },
    {
      path: 'snaps/create-new-snap',
      element: <SnapConfiguration />,
      roles: [admin]
    },
    {
      path: 'snaps/:id',
      element: <SnapConfiguration />,
      roles: [admin]
    },
    {
      path: 'mirrors',
      element: <MirrorList />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'mirrors/create-new-mirror',
      element: <MirrorConfiguration />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'mirrors/:id',
      element: <MirrorConfiguration />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'ar-namecard',
      element: <ArProjectList />,
      roles: [admin, ar_admin, ar_owner_namecard]
    },
    {
      path: 'sketchfab',
      element: <Sketchfab />,
      roles: [admin]
    },
    {
      path: 'ar-namecard/postcontent',
      element: <ArProjectConfiguration />,
      roles: [admin, ar_admin, ar_owner_namecard]
    },
    {
      path: 'ar-namecard/postcontent/:id',
      element: <ArProjectConfiguration />,
      roles: [admin, ar_admin, ar_owner_namecard]
    },
    {
      path: 'ar-templates',
      element: <ARTemplateList />,
      roles: [admin, ar_admin]
    },
    {
      path: 'ar-preview',
      element: <ARPreview />,
      roles: [ar_admin, admin]
    },
    {
      path: 'ar-templates/postcontent/',
      element: <ARTemplateConfiguration />,
      roles: [admin, ar_admin]
    },
    {
      path: 'ar-templates/:id',
      element: <ARTemplateConfiguration />,
      roles: [admin, ar_admin]
    },
    {
      path: 'quiz',
      element: <QuizList />,
      roles: [admin]
    },
    {
      path: 'quiz/create-new-quiz',
      element: <QuizConfiguration />,
      roles: [admin]
    },
    {
      path: 'quiz/:id',
      element: <QuizConfiguration />,
      roles: [admin]
    },
    {
      path: 'group-list',
      element: <GroupList />,
      roles: [admin, user_admin]
    },
    {
      path: 'group-list/create-new-group',
      element: <GroupConfiguration />,
      roles: [admin, user_admin]
    },
    {
      path: 'group-list/:id',
      element: <GroupConfiguration />,
      roles: [admin, user_admin]
    },
    {
      path: 'help',
      element: <HelpPage />
    },
    {
      path: 'change-password',
      element: <ChangePassword />
    },
    {
      path: 'premiere/editor',
      element: <EditorPremiere />,
      roles: [admin]
    },
    {
      path: 'ar-project-audit',
      element: <ArProjectAuditList />,
      roles: [ar_admin, admin]
    },
    {
      path: 'profile-audit',
      element: <ProfileAuditList />,
      roles: [admin]
    },
    {
      path: 'user-audit',
      element: <UserAuditList />,
      roles: [admin]
    },
    {
      path: 'mirror-audit',
      element: <MirrorAuditList />,
      roles: [admin]
    },
    {
      path: 'mirrors-assign-not-found',
      element: <SpecifiedNotFound />,
      roles: [mirror, mirror_viewer]
    },
    {
      path: 'qrcode',
      element: <QRcodeDynamic />
    },
    {
      path: 'expired-qrcode-login',
      element: <ExpiredQRCode />
    },
    {
      path: 'videos',
      element: <VideosList />,
      roles: [admin]
    },
    {
      path: 'videos/create-new-videos',
      element: <VideosListConfiguration />,
      roles: [admin]
    },
    {
      path: 'videos/:id',
      element: <VideosListConfiguration />,
      roles: [admin]
    },
    {
      path: 'ar-pamphlet/postcontent',
      element: <ArPamphletConfiguration />,
      roles: [admin, ar_admin, ar_owner_pamphlet]
    },
    {
      path: 'ar-pamphlet/postcontent/:id',
      element: <ArPamphletConfiguration />,
      roles: [admin, ar_admin, ar_owner_pamphlet]
    },
    {
      path: 'ar-pamphlet',
      element: <ArPamphletList />,
      roles: [admin, ar_admin, ar_owner_pamphlet]
    },
    {
      path: 'incident-details',
      element: <IncidentDetailList />
    },
    {
      path: 'metaverse/maps',
      element: <MapList />,
      roles: [admin]
    },
    {
      path: 'metaverse/maps/create',
      element: <MapConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/maps/:id',
      element: <MapConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/worlds',
      element: <WorldList />,
      roles: [admin]
    },
    {
      path: 'metaverse/worlds/create',
      element: <WorldConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/worlds/:id',
      element: <WorldConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/areas',
      element: <AreaList />,
      roles: [admin]
    },
    {
      path: 'metaverse/areas/create',
      element: <AreaConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/areas/:id',
      element: <AreaConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/weather/',
      element: <WeatherConfiguration />,
      roles: [admin]
    },
    {
      path: 'metaverse/feedbacks',
      element: <FeedbackList />,
      roles: [admin]
    },
    {
      path: 'metaverse/announcement-texts',
      element: <AnnouncementTextList />,
      roles: [admin]
    },
    {
      path: 'metaverse/announcement-texts/create',
      element: <AnnouncementTextConfig />,
      roles: [admin]
    },
    {
      path: 'metaverse/announcement-texts/:id',
      element: <AnnouncementTextConfig />,
      roles: [admin]
    },
    {
      path: 'ar-namecard/scan-statictis',
      element: <ARNameCardScanList />,
      roles: [admin, ar_admin]
    },
    {
      path: 'ar-pamphlet/scan-statictis',
      element: <ARPamphletScanList />,
      roles: [admin, ar_admin]
    }
  ]
};

export const routesMirrorsAssignNotFound = {
  path: 'reports',
  element: <SidebarLayoutV2 />,
  roles: [
    admin,
    mirror,
    ar_owner_namecard,
    ar_owner_pamphlet,
    user_admin,
    mirror_viewer
  ],
  children: [
    {
      path: '/',
      element: <Navigate to="/reports/mirrors-assign-not-found" replace />,
      roles: [admin, mirror, mirror_viewer]
    },
    {
      path: 'mirrors-assign-not-found',
      element: <SpecifiedNotFound />,
      roles: [mirror, mirror_viewer]
    },
    {
      path: 'change-password',
      element: <ChangePassword />
    }
  ]
};
