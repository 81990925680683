import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import mirrorApi from 'src/api_v2/mirror';
import { MirrorVoiceoverResponseType } from 'src/api_v2/mirror/types';
import profileApi from 'src/api_v2/profile';
import { ProfileVoiceoverResponseType } from 'src/api_v2/profile/types';
import {
  StepVoiceoverFileType,
  VOICEOVER_STEP,
  AdOverlayAndVideoRecordType,
  MirrorConfigurationType,
  MirrorFiltersType,
  MirrorNeutralVideos,
  UpdateNeutralVideoType,
  UpdateMirrorNeutralVideo
} from 'src/redux/store/models';
import {
  selectGeneralMirror,
  selectReservationTabData,
  selectVideoTabData
} from './selector';
import { mirrorConfigActions } from './slice';

function* fetchFilters() {
  try {
    const result: MirrorFiltersType = yield call(mirrorApi.fetchMirrorFilters);

    if (result) {
      yield put(mirrorConfigActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(mirrorConfigActions.filtersFetchedError());
  }
}

function* saveMirror(action: PayloadAction<MirrorConfigurationType>) {
  try {
    const result = yield call(mirrorApi.saveMirror, action.payload);

    if (result) {
      yield put(mirrorConfigActions.mirrorSaved());
      yield put(mirrorConfigActions.fetchMirror(result));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      const message = err.response.data?.message as string[];
      yield put(mirrorConfigActions.mirrorSavedError(message[0]));
    } else {
      yield put(mirrorConfigActions.mirrorSavedError(''));
    }
  }
}

function* fetchMirrorById(action: PayloadAction<string | number>) {
  try {
    const result = yield call(mirrorApi.fetchMirrorById, action.payload);

    if (result) {
      yield put(mirrorConfigActions.mirrorFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(mirrorConfigActions.mirrorFetchedError());
  }
}

function* fetchVoiceovers(
  action: PayloadAction<{
    mirrorId: number;
    isToggle: boolean;
    isOverride?: boolean;
  }>
) {
  // const page = yield select(selectPage);
  try {
    const result: any = yield call(
      mirrorApi.getMirrorVoiceovers,
      action.payload
    );

    if (result) {
      const setting: any = {
        id: result.id,
        mirrorId: result.mirrorId,
        isOverride: result.isOverride || false,
        theFirstIsOverride: result.isOverride
      };

      yield put(mirrorConfigActions.setVoiceSetting(setting));

      yield put(mirrorConfigActions.setVoiceovers(result.mirrorVoiceovers));
    }
  } catch (err) {
    if (err.response.status === 404) {
      yield put(mirrorConfigActions.voiceoversNotExisted());
    }
  }
}

function* toggleAdvOverlayAndRecordVideo(
  action: PayloadAction<AdOverlayAndVideoRecordType>
) {
  try {
    const result = yield call(
      mirrorApi.toggleAdOverlayAndVideoRecord,
      action.payload
    );

    if (result) {
      yield put(mirrorConfigActions.toggleDone());
    }
  } catch (err) {
    yield put(mirrorConfigActions.toggleError());
  }
}

function* fetchPhotos(action: PayloadAction<string | number>) {
  try {
    const result = yield call(mirrorApi.getPhotos, action.payload);

    if (result) {
      yield put(mirrorConfigActions.photosFetched(result));
    } else {
      yield put(mirrorConfigActions.photosNotExisted());
    }
  } catch (err) {
    yield put(mirrorConfigActions.photosNotExisted());
  }
}

function* fetchProfilePhotos(action: PayloadAction<string | number>) {
  try {
    const result = yield call(profileApi.getPhotos, action.payload);

    if (result) {
      yield put(
        mirrorConfigActions.photosFetched({ ...result, isOverride: false })
      );
    } else {
      yield put(mirrorConfigActions.photosNotExisted());
    }
  } catch (err) {
    yield put(mirrorConfigActions.photosNotExisted());
  }
}

function* fetchReservation(action: PayloadAction<string | number>) {
  try {
    const result = yield call(mirrorApi.getReservation, action.payload);

    yield put(mirrorConfigActions.reservationFetched(result));
  } catch (err) {
    yield put(mirrorConfigActions.reservationFetchedError());
  }
}

function* saveReservation() {
  try {
    const mirror = yield select(selectGeneralMirror);
    const { weekData } = yield select(selectReservationTabData);
    const result = yield call(mirrorApi.saveReservation, mirror.id, weekData);

    if (result) {
      yield put(mirrorConfigActions.reservationSaved());
      yield put(mirrorConfigActions.fetchReservation(mirror.id));
    } else {
      yield put(mirrorConfigActions.reservationSavedError());
    }
  } catch (err) {
    yield put(mirrorConfigActions.reservationSavedError());
  }
}

function* fetchNeutralVideos(action: PayloadAction<number>) {
  try {
    const result: MirrorNeutralVideos = yield call(
      mirrorApi.getVideos,
      action.payload
    );

    if (result) {
      yield put(mirrorConfigActions.videosFetched(result));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateNeutralVideos() {
  try {
    console.log("updateNeutralVideos")
    const { data, isOverride } = yield select(selectVideoTabData);
    const mirror = yield select(selectGeneralMirror);
    const payload: UpdateMirrorNeutralVideo = {
      videos: data.map((x, index) => ({ ...x, sequence: index + 1 })),
      isOverride: isOverride
    };

    const result = yield call(mirrorApi.updateVideos, mirror.id, payload);

    if (result) {
      yield put(mirrorConfigActions.videosUpdated());
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteNeutralVideo(action: PayloadAction<number>) {
  try {
    const mirror = yield select(selectGeneralMirror);
    const result = yield call(mirrorApi.deleteNeutralVideo, action.payload);

    if (result) {
      yield put(mirrorConfigActions.videoDeleted());

      yield put(mirrorConfigActions.fetchVideos(mirror.id));
    }
  } catch (err) {
  }
}

export default function* watchMirrorConfigurationSaga() {
  yield takeLatest(mirrorConfigActions.fetchFilters.type, fetchFilters);
  yield takeLatest(mirrorConfigActions.saveMirror.type, saveMirror);
  yield takeLatest(mirrorConfigActions.fetchMirror.type, fetchMirrorById);
  yield takeLatest(mirrorConfigActions.fetchVoiceovers.type, fetchVoiceovers);
  yield takeLatest(
    mirrorConfigActions.toggleAdvOverlayAndRecordVideo.type,
    toggleAdvOverlayAndRecordVideo
  );
  yield takeLatest(mirrorConfigActions.fetchPhotos.type, fetchPhotos);
  yield takeLatest(
    mirrorConfigActions.fetchProfilePhotos.type,
    fetchProfilePhotos
  );
  yield takeLatest(mirrorConfigActions.fetchReservation.type, fetchReservation);
  yield takeLatest(mirrorConfigActions.saveReservation.type, saveReservation);
  yield takeLatest(mirrorConfigActions.fetchVideos.type, fetchNeutralVideos);
  yield takeLatest(mirrorConfigActions.updateVideos.type, updateNeutralVideos);
  yield takeLatest(mirrorConfigActions.deleteVideo.type, deleteNeutralVideo);
}

export const mapToStepVoiceoverFileTypes = (
  result: MirrorVoiceoverResponseType | ProfileVoiceoverResponseType
) => {
  const step1 = {
    step: VOICEOVER_STEP.STEP1,
    files: [
      {
        voiceName: result.voiceover1Name,
        voicePath: result.voiceover1Path,
        gifName: result.gif1Name,
        gifPath: result.gif1Path
      },
      {
        voiceName: result.voiceover2Name,
        voicePath: result.voiceover2Path,
        gifName: result.gif2Name,
        gifPath: result.gif2Path
      },
      {
        voiceName: result.voiceover3Name,
        voicePath: result.voiceover3Path,
        gifName: result.gif3Name,
        gifPath: result.gif3Path
      }
    ]
  };

  const step2 = {
    step: VOICEOVER_STEP.STEP2,
    files: [
      {
        voiceName: result.voiceover4Name,
        voicePath: result.voiceover4Path,
        gifName: result.gif4Name,
        gifPath: result.gif4Path
      },
      {
        voiceName: result.voiceover5Name,
        voicePath: result.voiceover5Path,
        gifName: result.gif5Name,
        gifPath: result.gif5Path
      },
      {
        voiceName: result.voiceover6Name,
        voicePath: result.voiceover6Path,
        gifName: result.gif6Name,
        gifPath: result.gif6Path
      }
    ]
  };

  const step_nobody = {
    step: VOICEOVER_STEP.NOBODY,
    files: [
      {
        voiceName: result.voiceoverNobodyName,
        voicePath: result.voiceoverNobodyPath,
        gifName: result.gifNobodyName,
        gifPath: result.gifNobodyPath
      }
    ]
  };

  const step_fixed_duration = {
    step: VOICEOVER_STEP.FIXED_DURATION,
    files: [
      {
        voiceName: result.voiceoverFixedDurationName,
        voicePath: result.voiceoverFixedDurationPath,
        gifName: result.gifFixedDurationName,
        gifPath: result.gifFixedDurationPath
      }
    ]
  };

  const voiceoverFiles: StepVoiceoverFileType[] = [
    step1,
    step2,
    step_nobody,
    step_fixed_duration
  ];

  return voiceoverFiles;
};
