import { ReactNode } from 'react';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import MouseOutlinedIcon from '@mui/icons-material/MouseOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { messages } from 'src/locales/messages';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CropSquareOutlinedIcon from '@mui/icons-material/CropSquareOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import { UserRoles } from 'src/utils/constants';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import FilterDramaOutlinedIcon from '@mui/icons-material/FilterDramaOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: any;
  roles?: string[];
}

export interface MenuItems {
  items: MenuItem[];
  heading: any;
  collapseHeader?: boolean;
}

const admin = UserRoles.toString(UserRoles.Admin);
const mirror = UserRoles.toString(UserRoles.Mirrors_owner);
const ar_owner_namecard = UserRoles.toString(UserRoles.AR_owner_namecard);
const ar_owner_pamphlet = UserRoles.toString(UserRoles.AR_owner_pamphlet);
const ar_admin = UserRoles.toString(UserRoles.AR_admin);
const user_admin = UserRoles.toString(UserRoles.User_admin);
const mirror_viewer = UserRoles.toString(UserRoles.Mirror_viewer);

export const menuItems: MenuItems[] = [
  {
    heading: messages.sidebar.analytics,
    items: [
      {
        name: messages.sidebar.peopleCounter,
        icon: AnalyticsOutlinedIcon,
        roles: [admin, mirror, mirror_viewer],
        items: [
          {
            name: messages.sidebar.data,
            icon: FeedOutlinedIcon,
            link: 'people-counter/detailed',
            roles: [admin, mirror, mirror_viewer]
          },
          {
            name: messages.sidebar.chart,
            icon: EqualizerOutlinedIcon,
            link: 'people-counter/summary',
            roles: [admin, mirror, mirror_viewer]
          }
        ]
      },
      {
        name: messages.sidebar.clickTracking,
        icon: MouseOutlinedIcon,
        roles: [admin, mirror, mirror_viewer],
        items: [
          {
            name: messages.sidebar.data,
            icon: FeedOutlinedIcon,
            link: 'user-click-tracking',
            roles: [admin, mirror, mirror_viewer]
          },
          {
            name: messages.sidebar.chart,
            icon: EqualizerOutlinedIcon,
            link: 'chart-user-click-tracking',
            roles: [admin, mirror, mirror_viewer]
          }
        ]
      },
      {
        name: messages.sidebar.survey,
        icon: QuestionAnswerOutlinedIcon,
        link: 'survey',
        roles: [admin, mirror, mirror_viewer],
        items: [
          {
            name: messages.sidebar.data,
            icon: FeedOutlinedIcon,
            link: 'survey',
            roles: [admin, mirror, mirror_viewer]
          },
          {
            name: messages.sidebar.chart,
            icon: EqualizerOutlinedIcon,
            link: 'chart-survey',
            roles: [admin, mirror, mirror_viewer]
          }
        ]
      }
    ]
  },
  {
    heading: messages.sidebar.bigMirrors,
    items: [
      {
        name: messages.sidebar.categories,
        icon: CategoryOutlinedIcon,
        roles: [admin],
        items: [
          {
            name: messages.sidebar.snapList,
            icon: ViewModuleOutlinedIcon,
            link: 'snaps',
            roles: [admin]
          },
          {
            name: messages.sidebar.contentList,
            icon: SportsEsportsOutlinedIcon,
            link: 'contents',
            roles: [admin]
          },
          {
            name: messages.sidebar.mirrorType,
            icon: DesktopMacOutlinedIcon,
            link: 'mirror-types',
            roles: [admin]
          },
          {
            name: messages.sidebar.videosList,
            icon: VideoSettingsOutlinedIcon,
            link: 'videos',
            roles: [admin]
          }
        ]
      },
      {
        name: messages.sidebar.setupMirrors,
        icon: SettingsApplicationsOutlinedIcon,
        roles: [admin, mirror, mirror_viewer],
        items: [
          {
            name: messages.sidebar.mirrors,
            icon: VideoSettingsOutlinedIcon,
            link: 'mirrors',
            roles: [admin, mirror, mirror_viewer]
          },
          {
            name: messages.sidebar.profiles,
            icon: MiscellaneousServicesOutlinedIcon,
            link: 'profiles',
            roles: [admin]
          }
        ]
      }
    ]
  },
  {
    heading: messages.sidebar.ARProjects,
    items: [
      {
        name: messages.sidebar.arMasterTemplates,
        icon: ListAltOutlinedIcon,
        link: 'ar-templates',
        roles: [admin, ar_admin]
      },
      {
        name: messages.sidebar.arNameCard,
        icon: AccountTreeOutlinedIcon,
        link: 'ar-namecard',
        roles: [admin, ar_owner_namecard, ar_admin]
      },
      {
        name: messages.sidebar.arPamphlet,
        icon: AutoAwesomeMotionOutlinedIcon,
        link: 'ar-pamphlet',
        roles: [ar_admin, admin, ar_owner_pamphlet]
      },
      {
        name: messages.sidebar.sketchFab,
        icon: ViewInArOutlinedIcon,
        link: 'sketchfab',
        roles: [admin]
      },
      {
        name: messages.sidebar.arPreview,
        icon: SlowMotionVideoOutlinedIcon,
        link: 'ar-preview',
        roles: [ar_admin, admin]
      },
      {
        name: messages.sidebar.monitoring,
        icon: ScreenSearchDesktopOutlinedIcon,
        roles: [ar_admin, admin],
        items: [
          {
            name: messages.sidebar.arScanNamecard,
            icon: ManageSearchOutlinedIcon,
            link: 'ar-namecard/scan-statictis',
            roles: [ar_admin, admin]
          },
          {
            name: messages.sidebar.arScanPamphlet,
            icon: ManageSearchOutlinedIcon,
            link: 'ar-pamphlet/scan-statictis',
            roles: [ar_admin, admin]
          }
        ]
      }
    ]
  },
  {
    heading: messages.sidebar.audit,
    items: [
      {
        name: messages.sidebar.audit,
        icon: ManageSearchOutlinedIcon,
        roles: [admin, ar_admin],
        items: [
          {
            name: messages.sidebar.arProjects,
            icon: AccountTreeOutlinedIcon,
            link: 'ar-project-audit',
            roles: [admin, ar_admin]
          },
          {
            name: messages.sidebar.userList,
            icon: ContactPageOutlinedIcon,
            link: 'user-audit',
            roles: [admin]
          },
          {
            name: messages.sidebar.profiles,
            icon: BadgeOutlinedIcon,
            link: 'profile-audit',
            roles: [admin]
          },
          {
            name: messages.sidebar.mirrors,
            icon: CropSquareOutlinedIcon,
            link: 'mirror-audit',
            roles: [admin]
          }
        ]
      }
    ]
  },
  {
    heading: messages.sidebar.userManagement,
    items: [
      {
        name: messages.sidebar.userManagement,
        icon: ManageAccountsOutlinedIcon,
        roles: [admin, user_admin],
        items: [
          {
            name: messages.sidebar.userList,
            icon: ManageAccountsOutlinedIcon,
            roles: [admin, user_admin],
            link: 'users-management'
          },
          {
            name: messages.sidebar.groupList,
            icon: GroupsOutlinedIcon,
            roles: [admin, user_admin],
            link: 'group-list'
          }
        ]
      }
    ]
  },
  {
    heading: messages.sidebar.quiz,
    items: [
      {
        name: messages.sidebar.quiz,
        icon: HelpOutlineOutlinedIcon,
        roles: [admin],
        link: 'quiz'
      }
    ]
  },
  {
    heading: messages.sidebar.helpMenu,
    items: [
      {
        name: messages.sidebar.helpMenu,
        icon: InfoOutlinedIcon,
        link: 'help',
        roles: [admin, mirror]
      },
      {
        name: messages.sidebar.incidentDetail,
        icon: ContactSupportOutlinedIcon,
        link: 'incident-details',
        roles: [admin]
      }
    ]
  },
  {
    heading: messages.sidebar.premierePro,
    items: [
      {
        name: messages.sidebar.editor,
        icon: FormatShapesOutlinedIcon,
        link: 'premiere/editor',
        roles: [admin]
      }
    ]
  },
  {
    heading: messages.sidebar.metaverse,
    items: [
      {
        name: messages.sidebar.metaSetting,
        icon: SettingsApplicationsOutlinedIcon,
        roles: [admin],
        items: [
          {
            name: messages.sidebar.map,
            icon: MapOutlinedIcon,
            link: 'metaverse/maps',
            roles: [admin]
          },
          {
            name: messages.sidebar.world,
            icon: PublicOutlinedIcon,
            link: 'metaverse/worlds',
            roles: [admin]
          },
          {
            name: messages.sidebar.area,
            icon: PlaceOutlinedIcon,
            link: 'metaverse/areas',
            roles: [admin]
          },
          {
            name: messages.sidebar.announcementText,
            icon: FormatColorTextOutlinedIcon,
            link: 'metaverse/announcement-texts',
            roles: [admin]
          },
          {
            name: messages.sidebar.weather,
            icon: FilterDramaOutlinedIcon,
            link: 'metaverse/weather',
            roles: [admin]
          }
        ]
      },
      {
        name: messages.sidebar.feedback,
        icon: FeedbackOutlinedIcon,
        link: 'metaverse/feedbacks',
        roles: [admin]
      }
    ]
  }
];
