import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  NeutralVideoType,
  ProfileConfigurationType,
  ProfileContentType,
  ProfileFiltersType,
  ProfilePhotoType,
  ProfileSnapType,
  StepVoiceoverFileType,
  VOICEOVER_STEP,
  VoiceoverType,
  MirrorVoiceoverType
} from 'src/redux/store/models';

export enum TYPE_VOICEOVER_ENUM {
  STEP1 = 0,
  STEP2 = 1,
  STEP_NOBODY = 2,
  STEP_FIXED_DURATION = 3
}

export interface ProfileConfigurationState {
  mirrorTab: {
    loading: boolean;
    error: boolean;
    mirrors: MirrorProfileItem[];
  };
  snapTab: {
    data: ProfileSnapType[];
    loading: boolean;
    error: boolean;
    saved: boolean;
  };
  contentTab: {
    data: ProfileContentType[];
    mirrorTypeContents: ProfileContentType[];
    loading: boolean;
    error: boolean;
    saved: boolean;
  };
  voiceoverTab: {
    setting: MirrorVoiceoverType;
    files: StepVoiceoverFileType[];
    voiceovers: VoiceoverType[],
    loading: boolean;
    error: string;
  };
  videoTab: {
    loading: boolean;
    error: string;
    data: NeutralVideoType[];
    updated: boolean;
    deleted: boolean;
  };
  photoTab: {
    loading: boolean;
    error: string;
    data: ProfilePhotoType;
  }
  loading: boolean;
  error: string;
  profile: ProfileConfigurationType;
  filters: ProfileFiltersType;
  saved: boolean;
}

export interface MirrorProfileItem{
  id: number,
  name: string,
  uuid: string,
  status?: string,
  isOverride?: boolean
}

export const initialState: ProfileConfigurationState = {
  loading: false,
  error: '',
  mirrorTab: {
    loading: false,
    mirrors: [],
    error: false
  },
  snapTab: {
    loading: false,
    data: [],
    error: false,
    saved: false
  },
  contentTab: {
    loading: false,
    data: [],
    error: false,
    saved: false,
    mirrorTypeContents: []
  },
  voiceoverTab: {
    loading: false,
    voiceovers: [],
    setting: {
      isOverride: false,
      enableNobody: false,
      enableStep1: false,
      enableStep2: false,
      enableFixedDuration: false,
      enableVoiceover: false,
      step1: 0,
      step2: 0,
      step_nobody: 0,
      step_fixed_duration: 0,
      id: 0,
      mirrorId: 0
    },
    error: '',
    files: [
      {
        step: VOICEOVER_STEP.STEP1,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.STEP2,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.NOBODY,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      },
      {
        step: VOICEOVER_STEP.FIXED_DURATION,
        files: [
          {
            voiceName: '',
            voicePath: '',
            gifName: '',
            gifPath: ''
          }
        ]
      }
    ]
  },
  videoTab: {
    data: [],
    loading: false,
    error: '',
    updated: false,
    deleted: false
  },
  profile: undefined,
  filters: {
    owners: [],
    inputType: [],
    mirrorTypes: []
  },
  photoTab: {
    loading: false,
    error: '',
    data: undefined
  },
  saved: false
};

const profileConfigurationSlice = createSlice({
  name: 'profileConfig',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchFilters(state: ProfileConfigurationState) {
      state.loading = true;
      state.error = '';
    },
    filtersFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileFiltersType>
    ) {
      state.loading = false;
      state.filters = action.payload;
    },
    filtersFetchedError(state: ProfileConfigurationState) {
      state.loading = false;
      state.error = 'Fail to fetch filters';
    },
    fetchMirrors(
      state: ProfileConfigurationState,
      action: PayloadAction<number>
    ) {
      state.mirrorTab.loading = true;
      state.mirrorTab.error = false;
    },
    mirrorsFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<MirrorProfileItem[]>
    ) {
      state.mirrorTab = {
        ...state.mirrorTab,
        loading: false,
        mirrors: action.payload
      };
    },
    mirrorsFetchedError(state: ProfileConfigurationState) {
      state.mirrorTab = {
        ...state.mirrorTab,
        loading: false,
        error: true
      };
    },
    fetchSnaps(
      state: ProfileConfigurationState,
      action: PayloadAction<number>
    ) {
      state.snapTab.loading = true;
      state.snapTab.error = false;
    },
    snapsFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileSnapType[]>
    ) {
      state.snapTab = {
        ...state.snapTab,
        loading: false,
        data: action.payload
      };
    },
    snapsFetchedError(state: ProfileConfigurationState) {
      state.snapTab = {
        ...state.snapTab,
        loading: false,
        error: true
      };
    },
    setSnaps(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileSnapType[]>
    ) {
      state.snapTab.data = action.payload;
    },
    saveSnaps(state: ProfileConfigurationState) {
      state.snapTab.loading = true;
      state.snapTab.error = false;
      state.snapTab.saved = false;
    },
    snapsSaved(state: ProfileConfigurationState) {
      state.snapTab.loading = false;
      state.snapTab.saved = true;
    },
    snapsSavedError(state: ProfileConfigurationState) {
      state.snapTab.loading = false;
      state.snapTab.error = true;
    },
    fetchContentsByProfile(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileConfigurationType>
    ) {
      state.contentTab.loading = true;
      state.contentTab.error = false;
    },
    contentsFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileContentType[]>
    ) {
      state.contentTab = {
        ...state.contentTab,
        loading: false,
        data: action.payload
      };
    },
    contentsFetchedError(state: ProfileConfigurationState) {
      state.contentTab = {
        ...state.contentTab,
        loading: false,
        error: true
      };
    },
    setContents(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileContentType[]>
    ) {
      state.contentTab.data = action.payload;
    },
    setMirrorTypeContents(state: ProfileConfigurationState,
      action: PayloadAction<ProfileContentType[]>) {
        state.contentTab.mirrorTypeContents = action.payload;
      },
    saveContents(state: ProfileConfigurationState) {
      state.contentTab.loading = true;
      state.contentTab.error = false;
      state.contentTab.saved = false;
    },
    contentsSaved(state: ProfileConfigurationState) {
      state.contentTab.loading = false;
      state.contentTab.saved = true;
    },
    contentsSavedError(state: ProfileConfigurationState) {
      state.contentTab.loading = false;
      state.contentTab.error = true;
    },
    saveProfile(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileConfigurationType>
    ) {
      state.loading = true;
      state.error = '';
    },
    profileSaved(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileConfigurationType>
    ) {
      state.loading = false;
      state.profile = action.payload;
      state.saved = true
    },
    setSaved(state: ProfileConfigurationState, action: PayloadAction<boolean>) {
      state.saved = action.payload;
    },
    profileSavedError(
      state: ProfileConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchProfile(
      state: ProfileConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = true;
      state.error = '';
    },
    profileFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<ProfileConfigurationType>
    ) {
      state.loading = false;
      state.profile = action.payload;
    },
    profileFetchedError(state: ProfileConfigurationState) {
      state.loading = false;
      state.error = 'Fail to load profile';
    },
    populateContentsOfMirrorType(
      state: ProfileConfigurationState,
      action: PayloadAction<string>
    ) {
      state.contentTab.loading = true;
    },
    fetchVideos(
      state: ProfileConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.videoTab.loading = true;
      state.videoTab.error = '';
    },
    videosFetched(
      state: ProfileConfigurationState,
      action: PayloadAction<NeutralVideoType[]>
    ) {
      state.videoTab.data = action.payload;
      state.videoTab.loading = false;
    },
    videoFetchedError(state: ProfileConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.error = 'Fail to fetch videos';
    },
    setVideos(
      state: ProfileConfigurationState,
      action: PayloadAction<NeutralVideoType[]>
    ) {
      state.videoTab.data = action.payload;
    },
    updateVideos(state: ProfileConfigurationState) {
      state.videoTab.loading = true;
      state.videoTab.error = '';
    },
    videosUpdated(state: ProfileConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.updated = true;
    },
    videosUpdatedError(state: ProfileConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.error = 'Fail to updated videos';
    },
    deleteVideo(
      state: ProfileConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.videoTab.loading = true;
      state.videoTab.deleted = false;
    },
    videoDeleted(state: ProfileConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.deleted = true;
    },
    videoDeletedError(state: ProfileConfigurationState) {
      state.videoTab.loading = false;
      state.videoTab.error = 'Fail to delete video';
    },
    setVideoDeleted(state: ProfileConfigurationState, action: PayloadAction<boolean>) {
      state.videoTab.deleted = action.payload
    },
    fetchVoiceovers(
      state: ProfileConfigurationState,
      action: PayloadAction<string | number>
    ) {
      state.voiceoverTab.loading = true;
      state.voiceoverTab.error = "";
    },
    setSetting(
      state: ProfileConfigurationState,
      action: PayloadAction<MirrorVoiceoverType>
    ) {
      state.voiceoverTab.setting = action.payload;
    },
    setFiles(
      state: ProfileConfigurationState,
      action: PayloadAction<StepVoiceoverFileType[]>
    ) {
      state.voiceoverTab.files = action.payload;
    },
    fetchPhotos(state: ProfileConfigurationState, action: PayloadAction<string| number>) {
      state.photoTab.loading = true;
      state.photoTab.error = ''
    },
    photosFetched(state: ProfileConfigurationState, action: PayloadAction<ProfilePhotoType>) {
      state.photoTab.loading = false
      state.photoTab.data = action.payload
    },
    photosFetchedError(state: ProfileConfigurationState) {
      state.photoTab.loading = false
      state.photoTab.error = 'Fail to fetch profile photos'
    },
    syncMirrorTypeContents(state: ProfileConfigurationState, action: PayloadAction<ProfileContentType[]>) {
      state.contentTab.loading = true
      state.contentTab.saved = false;
    },
    setVoiceovers(
      state: ProfileConfigurationState,
      action: PayloadAction<VoiceoverType[]>
    ) {
      state.voiceoverTab.voiceovers = action.payload
      state.voiceoverTab.loading = false;
    },
    setVoiceSetting(
      state: ProfileConfigurationState,
      action: PayloadAction<MirrorVoiceoverType>
    ) {
      state.voiceoverTab.setting = action.payload;
    },
    resetError(state: ProfileConfigurationState) {
      state.voiceoverTab.error = '';
      state.photoTab.error = '';
    },
  }
});

export default profileConfigurationSlice.reducer;
export const profileConfigActions = profileConfigurationSlice.actions;
